@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Somar';
  src: url('../public/fonts/Somar-Regular.otf') format('opentype'),
  url('../public/fonts/Somar-Black.otf') format('opentype'),
    url('../public/fonts/Somar-ExtraLight.otf') format('opentype'),
    url('../public/fonts/Somar-ExtraBold.otf') format('opentype'),
    url('../public/fonts/Somar-Medium.otf') format('opentype'),
    url('../public/fonts/Somar-Bold.otf') format('opentype'),
    url('../public/fonts/Somar-Light.otf') format('opentype'),
    url('../public/fonts/Somar-SemiBold.otf') format('opentype'),
    url('../public/fonts/Somar-Thin.otf') format('opentype'),

}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
  scrollbar-width: thin;
  scrollbar-color: #94C13D #F5F5F5;
  font-family: Somar !important;
  line-height: 1.5;
}
.no-scroll {
  overflow: auto;
  -ms-overflow-style: none;  
  scrollbar-width: none;     
}

.no-scroll::-webkit-scrollbar {
  display: none;             
}

ul {
  list-style: none;
}
th{
  text-align:start;
  padding: 4px ;
}
td{
  padding: 4px 10px;
}

.overlay {
  background: linear-gradient(to top, #000000de, #00000075);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}

.hero {
  background-image: url(../public/asset/news.jpg);
  background-size: cover;
}
.about-hero{
  background-image: url(../public/asset/hero-contact.webp);
  background-size: cover;
}
.contact-hero{
  background-image: url(../public/asset/hero-contact.webp);
  background-size: cover;
}
.products-container,.hitun-team{

  box-shadow:0px 1px 7px 4px rgba(103, 148, 17, 0.12);
}
.swiper-button-next::after,
.swiper-button-prev::after {
  color: #94C13D;
  background-color: #FFF !important;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  font-weight: bold;
  font-size:18px !important;
  min-width: 40px !important; 
  height:40px !important;
  box-shadow:0px 1px 7px 4px rgba(103, 148, 17, 0.12);
}
.brand-shadow{
  box-shadow:0px 1px 10px 4px rgba(103, 148, 17, 0.12);
}
.contact-icon{
  transition: all 5s ease-in-out;
  animation: upDown 2s ease-in-out  infinite forwards;
}

.sharp-gradient{
  background: linear-gradient(to top ,#94C13D 0%,#94C13D 50% ,#FFF 50%,#FFF 50% )
}
.swiper-pagination{
  padding: 20px 0;
}
.swiper-pagination>span{
  width: 15px;
  height: 15px;
  background-color: #f02d34;
  margin: 0 10px !important;
}
@layer utilities {
  .max-container {
    @apply mx-auto max-w-[1400px];
  }

  .padding-container {
    @apply xs:px-6 lg:px-20 3xl:px-0;
  }

  .flex-center {
    @apply flex items-center justify-center;
  }

  .flex-between {
    @apply flex items-center justify-between;
  }

  .main-prop {
    @apply py-20
  }
}
@keyframes upDown {
  0%{
    transform: translateY(0);
  }
  50%{
    transform: translateY(-5px);
  }
  100%{
    transform: translateY(0);
  }
}

